import Box from '@mui/material/Box';

import EmptyMessage from '../../components/EmptyMessage';
import { FoodComponent } from '../../libs';
import { useDocumentMedia } from '../../libs/utils/document';
import WastageTable from './Desktop/WastageTable';
import WastageTableMobile from './Mobile/WastageTable';

interface WastageTrackingProps {
	foodComponents: FoodComponent[];
}

const WastageTracking = ({ foodComponents }: WastageTrackingProps) => {
	const { isMobile } = useDocumentMedia();

	if (isMobile) {
		return (
			<Box sx={{ width: '95%', display: 'flex', flexDirection: 'column' }}>
				{foodComponents.length > 0 ? (
					<WastageTableMobile key={`wastage-tracking-${foodComponents.length}`} foodComponents={foodComponents || []} />
				) : (
					<EmptyMessage label={'No Data'} />
				)}
			</Box>
		);
	} else {
		return (
			<Box sx={{ width: '96%', minHeight: '100vh' }}>
				{foodComponents.length > 0 ? <WastageTable foodComponents={foodComponents || []} /> : <EmptyMessage label={'No Data'} />}
			</Box>
		);
	}
};

export default WastageTracking;
