import { format } from 'date-fns/fp';
import { groupBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { Dictionary } from '@calo/types';

import { useListChefsQuery, useListComponentBatchesQuery } from '../../hooks';
import { ComponentBatch, Kitchen, QualityCheckStatus, Session, SingleFoodComponentWithBatches } from '../../libs';
import { useDocumentMedia } from '../../libs/utils/document';
import DesktopQualityTracking from './Desktop/DesktopQualityTracking';
import MobileQualityTracking from './Mobile/MobileQualityTracking';
import { QualityTrackingContext } from './QualityTrackingContext';

const defaultStandards = [
	{
		id: '1',
		name: 'Color'
	},
	{
		id: '2',
		name: 'Taste'
	},
	{
		id: '3',
		name: 'Appearance'
	},
	{
		id: '4',
		name: 'Texture'
	},
	{
		id: '5',
		name: 'Smell'
	}
];

interface QualityTrackingProps {
	kitchen: Kitchen;
	date: string;
	shift: Session;
	foodComponents: SingleFoodComponentWithBatches[];
}

const QualityTracking = ({ shift, date, kitchen, foodComponents }: QualityTrackingProps) => {
	const { isMobile } = useDocumentMedia();
	const [selectedStation, setSelectedStation] = useState<string>('commissary');
	const [searchText, setSearchText] = useState('');
	const [shiftState, setShiftState] = useState<Session>(shift);

	const [selectedCheckStatuses, setSelectedCheckStatuses] = useState([
		QualityCheckStatus.Accepted,
		QualityCheckStatus.Pending,
		QualityCheckStatus.Rejected
	]);
	const dateRange = {
		gte: format('yyyy-MM-dd')(new Date(date)),
		lte: format('yyyy-MM-dd')(new Date(date))
	};

	const { componentBatchesLoading, batches, refetchBatches, clearBatchesData } = useListComponentBatchesQuery(
		kitchen,
		dateRange,
		shiftState
	);

	const { chefsLoading, chefs, refetchChefs } = useListChefsQuery(kitchen);

	const filteredComponentsByStation = useMemo(() => {
		return foodComponents.filter((fc) => fc.cookingStations && fc.cookingStations[fc.cookingStations.length - 1].name === selectedStation);
	}, [foodComponents, selectedStation]);

	const foodComponentsWithBatches: SingleFoodComponentWithBatches[] = useMemo(() => {
		const filteredComponentsByName = filteredComponentsByStation.filter((fc) => {
			return fc.name.en.match(new RegExp(`${searchText}`, 'i'));
		});

		const groupedComponentBatches: Dictionary<ComponentBatch[]> = groupBy(batches, 'foodComponentId');

		const foodComponentsWithBatches: SingleFoodComponentWithBatches[] = filteredComponentsByName.map((component) => ({
			...component,
			batches: groupedComponentBatches[component.id],
			standards: component.standards ?? defaultStandards
		}))

		return foodComponentsWithBatches;
	}, [filteredComponentsByStation, batches, searchText]);

	useEffect(() => {
		if (shift !== shiftState) {
			setShiftState(shift);
		}
		refetchBatches();
	}, [kitchen, date, shift]);

	useEffect(() => {
		refetchChefs();
	}, [kitchen]);

	const handleClearData = () => {
		clearBatchesData();
		refetchBatches();
	};

	return (
		<QualityTrackingContext.Provider
			value={{
				chefs,
				date,
				kitchen,
				shifts: shiftState,
				setShifts: setShiftState,
				clearData: handleClearData,
				batches,
				selectedStation,
				setSelectedStation,
				isLoading: chefsLoading || componentBatchesLoading,
				foodComponentsWithBatches,
				selectedCheckStatuses: selectedCheckStatuses,
				setSelectedCheckStatuses: setSelectedCheckStatuses,
				searchText,
				setSearchText
			}}
		>
			{isMobile ? <MobileQualityTracking /> : <DesktopQualityTracking />}
		</QualityTrackingContext.Provider>
	);
};
export default QualityTracking;
