import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';
import { Range } from '@calo/dashboard-types';

import { ComponentBatch } from '../libs';
import { GraphQLClient, LIST_COMPONENT_BATCHES_QUERY, SUBSCRIBE_TO_COMPONENT_BATCH_CHANGES_SUBSCRIPTION } from '../libs/graphQL';

const useListComponentBatchesQuery = (kitchen: string, date: Range, shift: string) => {
	const [batches, setBatches] = useState<ComponentBatch[]>([]);
	const { loading, refetch, subscribeToMore } = useQuery(LIST_COMPONENT_BATCHES_QUERY, {
		variables: { kitchen, date, shift },
		onCompleted(data) {
			if (data?.listComponentBatches?.data) {
				setBatches(data.listComponentBatches.data);
			}
		},
		onError(error) {
			toast.error(error.message);
		}
	});
	useSubscription(SUBSCRIBE_TO_COMPONENT_BATCH_CHANGES_SUBSCRIPTION);

	const moreBatches = () =>
		subscribeToMore({
			document: SUBSCRIBE_TO_COMPONENT_BATCH_CHANGES_SUBSCRIPTION,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const prevData = prev?.listComponentBatches?.data || [];
				const subscribeToComponentBatches = subscriptionData.data.subscribeToComponentBatchChanges;

				return {
					...prev,
					listComponentBatches: {
						...prev.listComponentBatches,
						data: uniqBy([subscribeToComponentBatches, ...prevData], 'id')
					}
				};
			}
		});

	useEffect(() => {
		moreBatches();
	}, []);

	const clearData = () => {
		setBatches([]);
		GraphQLClient.clearStore();
	};

	return {
		componentBatchesLoading: loading,
		batches,
		refetchBatches: refetch,
		clearBatchesData: clearData
	};
};

export default useListComponentBatchesQuery;
